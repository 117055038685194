// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Js_promise = require("rescript/lib/js/js_promise.js");
var Caml_option = require("rescript/lib/js/caml_option.js");

function exposeFieldResult(fieldStatus) {
  if (typeof fieldStatus !== "object" || fieldStatus._1 !== "Shown") {
    return ;
  } else {
    return fieldStatus._0;
  }
}

function validateFieldOnChangeWithoutValidator(fieldInput, setStatus) {
  return Curry._1(setStatus, {
              TAG: "Dirty",
              _0: {
                TAG: "Ok",
                _0: fieldInput
              },
              _1: "Hidden"
            });
}

function validateFieldOnChangeWithValidator(input, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  var exit$1 = 0;
  if (typeof fieldStatus !== "object" || fieldStatus._1 !== "Shown") {
    exit$1 = 3;
  } else {
    exit = 2;
  }
  if (exit$1 === 3) {
    if (submissionStatus === "NeverSubmitted") {
      switch (match) {
        case "OnFirstChange" :
            exit = 2;
            break;
        case "OnFirstSuccess" :
        case "OnFirstSuccessOrFirstBlur" :
            exit = 1;
            break;
        case "OnFirstBlur" :
        case "OnSubmit" :
            return Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: Curry._1(validator.validate, input),
                        _1: "Hidden"
                      });
        
      }
    } else {
      exit = 2;
    }
  }
  switch (exit) {
    case 1 :
        var result = Curry._1(validator.validate, input);
        if (result.TAG === "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Shown"
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Hidden"
                    });
        }
    case 2 :
        return Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: Curry._1(validator.validate, input),
                    _1: "Shown"
                  });
    
  }
}

function validateFieldOnChangeWithValidatorAndMetadata(input, metadata, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  var exit$1 = 0;
  if (typeof fieldStatus !== "object" || fieldStatus._1 !== "Shown") {
    exit$1 = 4;
  } else {
    exit = 3;
  }
  if (exit$1 === 4) {
    if (submissionStatus === "NeverSubmitted") {
      switch (match) {
        case "OnFirstChange" :
            exit = 3;
            break;
        case "OnFirstSuccess" :
        case "OnFirstSuccessOrFirstBlur" :
            exit = 1;
            break;
        case "OnFirstBlur" :
        case "OnSubmit" :
            exit = 2;
            break;
        
      }
    } else {
      exit = 3;
    }
  }
  switch (exit) {
    case 1 :
        var result = Curry._2(validator.validate, input, metadata);
        if (result.TAG === "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Shown"
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Hidden"
                    });
        }
    case 2 :
        return Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: Curry._2(validator.validate, input, metadata),
                    _1: "Hidden"
                  });
    case 3 :
        return Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: Curry._2(validator.validate, input, metadata),
                    _1: "Shown"
                  });
    
  }
}

function validateFieldOfCollectionOnChangeWithValidator(input, index, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  var exit$1 = 0;
  if (typeof fieldStatus !== "object" || fieldStatus._1 !== "Shown") {
    exit$1 = 4;
  } else {
    exit = 3;
  }
  if (exit$1 === 4) {
    if (submissionStatus === "NeverSubmitted") {
      switch (match) {
        case "OnFirstChange" :
            exit = 3;
            break;
        case "OnFirstSuccess" :
        case "OnFirstSuccessOrFirstBlur" :
            exit = 1;
            break;
        case "OnFirstBlur" :
        case "OnSubmit" :
            exit = 2;
            break;
        
      }
    } else {
      exit = 3;
    }
  }
  switch (exit) {
    case 1 :
        var result = Curry._2(validator.validate, input, index);
        if (result.TAG === "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Shown"
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Hidden"
                    });
        }
    case 2 :
        return Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: Curry._2(validator.validate, input, index),
                    _1: "Hidden"
                  });
    case 3 :
        return Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: Curry._2(validator.validate, input, index),
                    _1: "Shown"
                  });
    
  }
}

function validateFieldOfCollectionOnChangeWithValidatorAndMetadata(input, index, metadata, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  var exit$1 = 0;
  if (typeof fieldStatus !== "object" || fieldStatus._1 !== "Shown") {
    exit$1 = 4;
  } else {
    exit = 3;
  }
  if (exit$1 === 4) {
    if (submissionStatus === "NeverSubmitted") {
      switch (match) {
        case "OnFirstChange" :
            exit = 3;
            break;
        case "OnFirstSuccess" :
        case "OnFirstSuccessOrFirstBlur" :
            exit = 1;
            break;
        case "OnFirstBlur" :
        case "OnSubmit" :
            exit = 2;
            break;
        
      }
    } else {
      exit = 3;
    }
  }
  switch (exit) {
    case 1 :
        var result = Curry._3(validator.validate, input, index, metadata);
        if (result.TAG === "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Shown"
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Hidden"
                    });
        }
    case 2 :
        return Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: Curry._3(validator.validate, input, index, metadata),
                    _1: "Hidden"
                  });
    case 3 :
        return Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: Curry._3(validator.validate, input, index, metadata),
                    _1: "Shown"
                  });
    
  }
}

function validateDependentFieldOnChange(input, fieldStatus, validator, setStatus) {
  if (typeof fieldStatus !== "object" || fieldStatus._1 !== "Shown") {
    return ;
  } else {
    return Caml_option.some(Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: Curry._1(validator.validate, input),
                    _1: "Shown"
                  }));
  }
}

function validateDependentFieldOnChangeWithMetadata(input, metadata, fieldStatus, validator, setStatus) {
  if (typeof fieldStatus !== "object" || fieldStatus._1 !== "Shown") {
    return ;
  } else {
    return Caml_option.some(Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: Curry._2(validator.validate, input, metadata),
                    _1: "Shown"
                  }));
  }
}

function validateDependentFieldOfCollectionOnChange(input, index, fieldStatus, validator, setStatus) {
  if (typeof fieldStatus !== "object" || fieldStatus._1 !== "Shown") {
    return ;
  } else {
    return Caml_option.some(Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: Curry._2(validator.validate, input, index),
                    _1: "Shown"
                  }));
  }
}

function validateDependentFieldOfCollectionOnChangeWithMetadata(input, index, metadata, fieldStatus, validator, setStatus) {
  if (typeof fieldStatus !== "object" || fieldStatus._1 !== "Shown") {
    return ;
  } else {
    return Caml_option.some(Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: Curry._3(validator.validate, input, index, metadata),
                    _1: "Shown"
                  }));
  }
}

function validateFieldOnBlurWithoutValidator(fieldInput, fieldStatus, setStatus) {
  if (typeof fieldStatus !== "object") {
    return Caml_option.some(Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: {
                      TAG: "Ok",
                      _0: fieldInput
                    },
                    _1: "Hidden"
                  }));
  }
  
}

function validateFieldOnBlurWithValidator(input, fieldStatus, validator, setStatus) {
  var exit = 0;
  if (typeof fieldStatus !== "object") {
    exit = 1;
  } else {
    if (fieldStatus._1 === "Shown") {
      return ;
    }
    exit = 1;
  }
  if (exit === 1) {
    var match = validator.strategy;
    var exit$1 = 0;
    switch (match) {
      case "OnFirstBlur" :
      case "OnFirstSuccessOrFirstBlur" :
          exit$1 = 2;
          break;
      default:
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: Curry._1(validator.validate, input),
                        _1: "Hidden"
                      }));
    }
    if (exit$1 === 2) {
      return Caml_option.some(Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: Curry._1(validator.validate, input),
                      _1: "Shown"
                    }));
    }
    
  }
  
}

function validateFieldOnBlurWithValidatorAndMetadata(input, metadata, fieldStatus, validator, setStatus) {
  var exit = 0;
  if (typeof fieldStatus !== "object") {
    exit = 1;
  } else {
    if (fieldStatus._1 === "Shown") {
      return ;
    }
    exit = 1;
  }
  if (exit === 1) {
    var match = validator.strategy;
    var exit$1 = 0;
    switch (match) {
      case "OnFirstBlur" :
      case "OnFirstSuccessOrFirstBlur" :
          exit$1 = 2;
          break;
      default:
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: Curry._2(validator.validate, input, metadata),
                        _1: "Hidden"
                      }));
    }
    if (exit$1 === 2) {
      return Caml_option.some(Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: Curry._2(validator.validate, input, metadata),
                      _1: "Shown"
                    }));
    }
    
  }
  
}

function validateFieldOfCollectionOnBlurWithValidator(input, index, fieldStatus, validator, setStatus) {
  var exit = 0;
  if (typeof fieldStatus !== "object") {
    exit = 1;
  } else {
    if (fieldStatus._1 === "Shown") {
      return ;
    }
    exit = 1;
  }
  if (exit === 1) {
    var match = validator.strategy;
    var exit$1 = 0;
    switch (match) {
      case "OnFirstBlur" :
      case "OnFirstSuccessOrFirstBlur" :
          exit$1 = 2;
          break;
      default:
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: Curry._2(validator.validate, input, index),
                        _1: "Hidden"
                      }));
    }
    if (exit$1 === 2) {
      return Caml_option.some(Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: Curry._2(validator.validate, input, index),
                      _1: "Shown"
                    }));
    }
    
  }
  
}

function validateFieldOfCollectionOnBlurWithValidatorAndMetadata(input, index, metadata, fieldStatus, validator, setStatus) {
  var exit = 0;
  if (typeof fieldStatus !== "object") {
    exit = 1;
  } else {
    if (fieldStatus._1 === "Shown") {
      return ;
    }
    exit = 1;
  }
  if (exit === 1) {
    var match = validator.strategy;
    var exit$1 = 0;
    switch (match) {
      case "OnFirstBlur" :
      case "OnFirstSuccessOrFirstBlur" :
          exit$1 = 2;
          break;
      default:
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: Curry._3(validator.validate, input, index, metadata),
                        _1: "Hidden"
                      }));
    }
    if (exit$1 === 2) {
      return Caml_option.some(Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: Curry._3(validator.validate, input, index, metadata),
                      _1: "Shown"
                    }));
    }
    
  }
  
}

function validateAsync(value, validate, andThen) {
  var __x = Curry._1(validate, value);
  Js_promise.then_((function (res) {
          return Promise.resolve(Curry._1(andThen, res));
        }), __x);
}

function validateAsyncWithMetadata(value, metadata, validate, andThen) {
  var __x = Curry._2(validate, value, metadata);
  Js_promise.then_((function (res) {
          return Promise.resolve(Curry._1(andThen, res));
        }), __x);
}

function exposeFieldResult$1(fieldStatus) {
  if (typeof fieldStatus !== "object") {
    return ;
  } else if (fieldStatus.TAG === "Dirty") {
    if (fieldStatus._1 === "Shown") {
      return {
              TAG: "Result",
              _0: fieldStatus._0
            };
    } else {
      return ;
    }
  } else {
    return {
            TAG: "Validating",
            _0: fieldStatus._0
          };
  }
}

function validateFieldOnChangeInOnBlurMode(input, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  if (typeof fieldStatus !== "object" || !(fieldStatus.TAG === "Dirty" && fieldStatus._1 === "Shown")) {
    exit = 2;
  }
  if (exit === 2 && submissionStatus === "NeverSubmitted" && match !== "OnFirstChange") {
    return Curry._1(setStatus, {
                TAG: "Dirty",
                _0: Curry._1(validator.validate, input),
                _1: "Hidden"
              });
  }
  var result = Curry._1(validator.validate, input);
  if (result.TAG === "Ok") {
    return Curry._1(setStatus, {
                TAG: "Dirty",
                _0: result,
                _1: "Hidden"
              });
  } else {
    return Curry._1(setStatus, {
                TAG: "Dirty",
                _0: result,
                _1: "Shown"
              });
  }
}

function validateFieldOnChangeInOnBlurModeWithMetadata(input, metadata, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  if (typeof fieldStatus !== "object" || !(fieldStatus.TAG === "Dirty" && fieldStatus._1 === "Shown")) {
    exit = 2;
  }
  if (exit === 2 && submissionStatus === "NeverSubmitted" && match !== "OnFirstChange") {
    return Curry._1(setStatus, {
                TAG: "Dirty",
                _0: Curry._2(validator.validate, input, metadata),
                _1: "Hidden"
              });
  }
  var result = Curry._2(validator.validate, input, metadata);
  if (result.TAG === "Ok") {
    return Curry._1(setStatus, {
                TAG: "Dirty",
                _0: result,
                _1: "Hidden"
              });
  } else {
    return Curry._1(setStatus, {
                TAG: "Dirty",
                _0: result,
                _1: "Shown"
              });
  }
}

function validateFieldOfCollectionOnChangeInOnBlurMode(input, index, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  if (typeof fieldStatus !== "object" || !(fieldStatus.TAG === "Dirty" && fieldStatus._1 === "Shown")) {
    exit = 2;
  }
  if (exit === 2 && submissionStatus === "NeverSubmitted" && match !== "OnFirstChange") {
    return Curry._1(setStatus, {
                TAG: "Dirty",
                _0: Curry._2(validator.validate, input, index),
                _1: "Hidden"
              });
  }
  var result = Curry._2(validator.validate, input, index);
  if (result.TAG === "Ok") {
    return Curry._1(setStatus, {
                TAG: "Dirty",
                _0: result,
                _1: "Hidden"
              });
  } else {
    return Curry._1(setStatus, {
                TAG: "Dirty",
                _0: result,
                _1: "Shown"
              });
  }
}

function validateFieldOfCollectionOnChangeInOnBlurModeWithMetadata(input, index, metadata, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  if (typeof fieldStatus !== "object" || !(fieldStatus.TAG === "Dirty" && fieldStatus._1 === "Shown")) {
    exit = 2;
  }
  if (exit === 2 && submissionStatus === "NeverSubmitted" && match !== "OnFirstChange") {
    return Curry._1(setStatus, {
                TAG: "Dirty",
                _0: Curry._3(validator.validate, input, index, metadata),
                _1: "Hidden"
              });
  }
  var result = Curry._3(validator.validate, input, index, metadata);
  if (result.TAG === "Ok") {
    return Curry._1(setStatus, {
                TAG: "Dirty",
                _0: result,
                _1: "Hidden"
              });
  } else {
    return Curry._1(setStatus, {
                TAG: "Dirty",
                _0: result,
                _1: "Shown"
              });
  }
}

function validateFieldOfOptionTypeOnChangeInOnBlurMode(input, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  var exit$1 = 0;
  if (typeof fieldStatus !== "object" || !(fieldStatus.TAG === "Dirty" && fieldStatus._1 === "Shown")) {
    exit$1 = 3;
  } else {
    exit = 2;
  }
  if (exit$1 === 3) {
    if (submissionStatus === "NeverSubmitted") {
      switch (match) {
        case "OnFirstChange" :
            exit = 2;
            break;
        case "OnFirstSuccess" :
        case "OnFirstSuccessOrFirstBlur" :
            exit = 1;
            break;
        case "OnFirstBlur" :
        case "OnSubmit" :
            return Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: Curry._1(validator.validate, input),
                        _1: "Hidden"
                      });
        
      }
    } else {
      exit = 2;
    }
  }
  switch (exit) {
    case 1 :
        var result = Curry._1(validator.validate, input);
        if (result.TAG === "Ok" && result._0 === undefined) {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Shown"
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Hidden"
                    });
        }
    case 2 :
        var result$1 = Curry._1(validator.validate, input);
        if (result$1.TAG === "Ok" && result$1._0 !== undefined) {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result$1,
                      _1: "Hidden"
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result$1,
                      _1: "Shown"
                    });
        }
    
  }
}

function validateFieldOfOptionTypeOnChangeInOnBlurModeWithMetadata(input, metadata, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  var exit$1 = 0;
  if (typeof fieldStatus !== "object" || !(fieldStatus.TAG === "Dirty" && fieldStatus._1 === "Shown")) {
    exit$1 = 4;
  } else {
    exit = 3;
  }
  if (exit$1 === 4) {
    if (submissionStatus === "NeverSubmitted") {
      switch (match) {
        case "OnFirstChange" :
            exit = 3;
            break;
        case "OnFirstSuccess" :
        case "OnFirstSuccessOrFirstBlur" :
            exit = 1;
            break;
        case "OnFirstBlur" :
        case "OnSubmit" :
            exit = 2;
            break;
        
      }
    } else {
      exit = 3;
    }
  }
  switch (exit) {
    case 1 :
        var result = Curry._2(validator.validate, input, metadata);
        if (result.TAG === "Ok" && result._0 === undefined) {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Shown"
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Hidden"
                    });
        }
    case 2 :
        return Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: Curry._2(validator.validate, input, metadata),
                    _1: "Hidden"
                  });
    case 3 :
        var result$1 = Curry._2(validator.validate, input, metadata);
        if (result$1.TAG === "Ok" && result$1._0 !== undefined) {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result$1,
                      _1: "Hidden"
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result$1,
                      _1: "Shown"
                    });
        }
    
  }
}

function validateFieldOfCollectionOfOptionTypeOnChangeInOnBlurMode(input, index, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  var exit$1 = 0;
  if (typeof fieldStatus !== "object" || !(fieldStatus.TAG === "Dirty" && fieldStatus._1 === "Shown")) {
    exit$1 = 4;
  } else {
    exit = 3;
  }
  if (exit$1 === 4) {
    if (submissionStatus === "NeverSubmitted") {
      switch (match) {
        case "OnFirstChange" :
            exit = 3;
            break;
        case "OnFirstSuccess" :
        case "OnFirstSuccessOrFirstBlur" :
            exit = 1;
            break;
        case "OnFirstBlur" :
        case "OnSubmit" :
            exit = 2;
            break;
        
      }
    } else {
      exit = 3;
    }
  }
  switch (exit) {
    case 1 :
        var result = Curry._2(validator.validate, input, index);
        if (result.TAG === "Ok" && result._0 === undefined) {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Shown"
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Hidden"
                    });
        }
    case 2 :
        return Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: Curry._2(validator.validate, input, index),
                    _1: "Hidden"
                  });
    case 3 :
        var result$1 = Curry._2(validator.validate, input, index);
        if (result$1.TAG === "Ok" && result$1._0 !== undefined) {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result$1,
                      _1: "Hidden"
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result$1,
                      _1: "Shown"
                    });
        }
    
  }
}

function validateFieldOfCollectionOfOptionTypeOnChangeInOnBlurModeWithMetadata(input, metadata, index, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  var exit$1 = 0;
  if (typeof fieldStatus !== "object" || !(fieldStatus.TAG === "Dirty" && fieldStatus._1 === "Shown")) {
    exit$1 = 4;
  } else {
    exit = 3;
  }
  if (exit$1 === 4) {
    if (submissionStatus === "NeverSubmitted") {
      switch (match) {
        case "OnFirstChange" :
            exit = 3;
            break;
        case "OnFirstSuccess" :
        case "OnFirstSuccessOrFirstBlur" :
            exit = 1;
            break;
        case "OnFirstBlur" :
        case "OnSubmit" :
            exit = 2;
            break;
        
      }
    } else {
      exit = 3;
    }
  }
  switch (exit) {
    case 1 :
        var result = Curry._3(validator.validate, input, index, metadata);
        if (result.TAG === "Ok" && result._0 === undefined) {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Shown"
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Hidden"
                    });
        }
    case 2 :
        return Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: Curry._3(validator.validate, input, index, metadata),
                    _1: "Hidden"
                  });
    case 3 :
        var result$1 = Curry._3(validator.validate, input, index, metadata);
        if (result$1.TAG === "Ok" && result$1._0 !== undefined) {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result$1,
                      _1: "Hidden"
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result$1,
                      _1: "Shown"
                    });
        }
    
  }
}

function validateFieldOfStringTypeOnChangeInOnBlurMode(input, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  var exit$1 = 0;
  if (typeof fieldStatus !== "object" || !(fieldStatus.TAG === "Dirty" && fieldStatus._1 === "Shown")) {
    exit$1 = 3;
  } else {
    exit = 2;
  }
  if (exit$1 === 3) {
    if (submissionStatus === "NeverSubmitted") {
      switch (match) {
        case "OnFirstChange" :
            exit = 2;
            break;
        case "OnFirstSuccess" :
        case "OnFirstSuccessOrFirstBlur" :
            exit = 1;
            break;
        case "OnFirstBlur" :
        case "OnSubmit" :
            return Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: Curry._1(validator.validate, input),
                        _1: "Hidden"
                      });
        
      }
    } else {
      exit = 2;
    }
  }
  switch (exit) {
    case 1 :
        var result = Curry._1(validator.validate, input);
        if (result.TAG === "Ok" && result._0 === "") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Shown"
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Hidden"
                    });
        }
    case 2 :
        var result$1 = Curry._1(validator.validate, input);
        if (result$1.TAG === "Ok" && result$1._0 !== "") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result$1,
                      _1: "Hidden"
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result$1,
                      _1: "Shown"
                    });
        }
    
  }
}

function validateFieldOfStringTypeOnChangeInOnBlurModeWithMetadata(input, metadata, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  var exit$1 = 0;
  if (typeof fieldStatus !== "object" || !(fieldStatus.TAG === "Dirty" && fieldStatus._1 === "Shown")) {
    exit$1 = 4;
  } else {
    exit = 3;
  }
  if (exit$1 === 4) {
    if (submissionStatus === "NeverSubmitted") {
      switch (match) {
        case "OnFirstChange" :
            exit = 3;
            break;
        case "OnFirstSuccess" :
        case "OnFirstSuccessOrFirstBlur" :
            exit = 1;
            break;
        case "OnFirstBlur" :
        case "OnSubmit" :
            exit = 2;
            break;
        
      }
    } else {
      exit = 3;
    }
  }
  switch (exit) {
    case 1 :
        var result = Curry._2(validator.validate, input, metadata);
        if (result.TAG === "Ok" && result._0 === "") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Shown"
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Hidden"
                    });
        }
    case 2 :
        return Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: Curry._2(validator.validate, input, metadata),
                    _1: "Hidden"
                  });
    case 3 :
        var result$1 = Curry._2(validator.validate, input, metadata);
        if (result$1.TAG === "Ok" && result$1._0 !== "") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result$1,
                      _1: "Hidden"
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result$1,
                      _1: "Shown"
                    });
        }
    
  }
}

function validateFieldOfCollectionOfStringTypeOnChangeInOnBlurMode(input, index, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  var exit$1 = 0;
  if (typeof fieldStatus !== "object" || !(fieldStatus.TAG === "Dirty" && fieldStatus._1 === "Shown")) {
    exit$1 = 4;
  } else {
    exit = 3;
  }
  if (exit$1 === 4) {
    if (submissionStatus === "NeverSubmitted") {
      switch (match) {
        case "OnFirstChange" :
            exit = 3;
            break;
        case "OnFirstSuccess" :
        case "OnFirstSuccessOrFirstBlur" :
            exit = 1;
            break;
        case "OnFirstBlur" :
        case "OnSubmit" :
            exit = 2;
            break;
        
      }
    } else {
      exit = 3;
    }
  }
  switch (exit) {
    case 1 :
        var result = Curry._2(validator.validate, input, index);
        if (result.TAG === "Ok" && result._0 === "") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Shown"
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Hidden"
                    });
        }
    case 2 :
        return Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: Curry._2(validator.validate, input, index),
                    _1: "Hidden"
                  });
    case 3 :
        var result$1 = Curry._2(validator.validate, input, index);
        if (result$1.TAG === "Ok" && result$1._0 !== "") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result$1,
                      _1: "Hidden"
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result$1,
                      _1: "Shown"
                    });
        }
    
  }
}

function validateFieldOfCollectionOfStringTypeOnChangeInOnBlurModeWithMetadata(input, index, metadata, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  var exit$1 = 0;
  if (typeof fieldStatus !== "object" || !(fieldStatus.TAG === "Dirty" && fieldStatus._1 === "Shown")) {
    exit$1 = 4;
  } else {
    exit = 3;
  }
  if (exit$1 === 4) {
    if (submissionStatus === "NeverSubmitted") {
      switch (match) {
        case "OnFirstChange" :
            exit = 3;
            break;
        case "OnFirstSuccess" :
        case "OnFirstSuccessOrFirstBlur" :
            exit = 1;
            break;
        case "OnFirstBlur" :
        case "OnSubmit" :
            exit = 2;
            break;
        
      }
    } else {
      exit = 3;
    }
  }
  switch (exit) {
    case 1 :
        var result = Curry._3(validator.validate, input, index, metadata);
        if (result.TAG === "Ok" && result._0 === "") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Shown"
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Hidden"
                    });
        }
    case 2 :
        return Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: Curry._3(validator.validate, input, index, metadata),
                    _1: "Hidden"
                  });
    case 3 :
        var result$1 = Curry._3(validator.validate, input, index, metadata);
        if (result$1.TAG === "Ok" && result$1._0 !== "") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result$1,
                      _1: "Hidden"
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result$1,
                      _1: "Shown"
                    });
        }
    
  }
}

function validateFieldOfOptionStringTypeOnChangeInOnBlurMode(input, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  var exit$1 = 0;
  if (typeof fieldStatus !== "object" || !(fieldStatus.TAG === "Dirty" && fieldStatus._1 === "Shown")) {
    exit$1 = 3;
  } else {
    exit = 2;
  }
  if (exit$1 === 3) {
    if (submissionStatus === "NeverSubmitted") {
      switch (match) {
        case "OnFirstChange" :
            exit = 2;
            break;
        case "OnFirstSuccess" :
        case "OnFirstSuccessOrFirstBlur" :
            exit = 1;
            break;
        case "OnFirstBlur" :
        case "OnSubmit" :
            return Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: Curry._1(validator.validate, input),
                        _1: "Hidden"
                      });
        
      }
    } else {
      exit = 2;
    }
  }
  switch (exit) {
    case 1 :
        var match$1 = Curry._1(validator.validate, input);
        if (match$1.TAG !== "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: match$1,
                      _1: "Hidden"
                    });
        }
        var match$2 = match$1._0;
        if (match$2 !== undefined && match$2 !== "") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: match$1,
                      _1: "Hidden"
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: match$1,
                      _1: "Shown"
                    });
        }
    case 2 :
        var result = Curry._1(validator.validate, input);
        if (result.TAG !== "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Shown"
                    });
        }
        var match$3 = result._0;
        if (match$3 !== undefined && match$3 !== "") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Hidden"
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Shown"
                    });
        }
    
  }
}

function validateFieldOfOptionStringTypeOnChangeInOnBlurModeWithMetadata(input, metadata, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  var exit$1 = 0;
  if (typeof fieldStatus !== "object" || !(fieldStatus.TAG === "Dirty" && fieldStatus._1 === "Shown")) {
    exit$1 = 4;
  } else {
    exit = 3;
  }
  if (exit$1 === 4) {
    if (submissionStatus === "NeverSubmitted") {
      switch (match) {
        case "OnFirstChange" :
            exit = 3;
            break;
        case "OnFirstSuccess" :
        case "OnFirstSuccessOrFirstBlur" :
            exit = 1;
            break;
        case "OnFirstBlur" :
        case "OnSubmit" :
            exit = 2;
            break;
        
      }
    } else {
      exit = 3;
    }
  }
  switch (exit) {
    case 1 :
        var match$1 = Curry._2(validator.validate, input, metadata);
        if (match$1.TAG !== "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: match$1,
                      _1: "Hidden"
                    });
        }
        var match$2 = match$1._0;
        if (match$2 !== undefined && match$2 !== "") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: match$1,
                      _1: "Hidden"
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: match$1,
                      _1: "Shown"
                    });
        }
    case 2 :
        return Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: Curry._2(validator.validate, input, metadata),
                    _1: "Hidden"
                  });
    case 3 :
        var result = Curry._2(validator.validate, input, metadata);
        if (result.TAG !== "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Shown"
                    });
        }
        var match$3 = result._0;
        if (match$3 !== undefined && match$3 !== "") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Hidden"
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Shown"
                    });
        }
    
  }
}

function validateFieldOfCollectionOfOptionStringTypeOnChangeInOnBlurMode(input, index, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  var exit$1 = 0;
  if (typeof fieldStatus !== "object" || !(fieldStatus.TAG === "Dirty" && fieldStatus._1 === "Shown")) {
    exit$1 = 4;
  } else {
    exit = 3;
  }
  if (exit$1 === 4) {
    if (submissionStatus === "NeverSubmitted") {
      switch (match) {
        case "OnFirstChange" :
            exit = 3;
            break;
        case "OnFirstSuccess" :
        case "OnFirstSuccessOrFirstBlur" :
            exit = 1;
            break;
        case "OnFirstBlur" :
        case "OnSubmit" :
            exit = 2;
            break;
        
      }
    } else {
      exit = 3;
    }
  }
  switch (exit) {
    case 1 :
        var match$1 = Curry._2(validator.validate, input, index);
        if (match$1.TAG !== "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: match$1,
                      _1: "Hidden"
                    });
        }
        var match$2 = match$1._0;
        if (match$2 !== undefined && match$2 !== "") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: match$1,
                      _1: "Hidden"
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: match$1,
                      _1: "Shown"
                    });
        }
    case 2 :
        return Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: Curry._2(validator.validate, input, index),
                    _1: "Hidden"
                  });
    case 3 :
        var result = Curry._2(validator.validate, input, index);
        if (result.TAG !== "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Shown"
                    });
        }
        var match$3 = result._0;
        if (match$3 !== undefined && match$3 !== "") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Hidden"
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Shown"
                    });
        }
    
  }
}

function validateFieldOfCollectionOfOptionStringTypeOnChangeInOnBlurModeWithMetadata(input, index, metadata, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  var exit$1 = 0;
  if (typeof fieldStatus !== "object" || !(fieldStatus.TAG === "Dirty" && fieldStatus._1 === "Shown")) {
    exit$1 = 4;
  } else {
    exit = 3;
  }
  if (exit$1 === 4) {
    if (submissionStatus === "NeverSubmitted") {
      switch (match) {
        case "OnFirstChange" :
            exit = 3;
            break;
        case "OnFirstSuccess" :
        case "OnFirstSuccessOrFirstBlur" :
            exit = 1;
            break;
        case "OnFirstBlur" :
        case "OnSubmit" :
            exit = 2;
            break;
        
      }
    } else {
      exit = 3;
    }
  }
  switch (exit) {
    case 1 :
        var match$1 = Curry._3(validator.validate, input, index, metadata);
        if (match$1.TAG !== "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: match$1,
                      _1: "Hidden"
                    });
        }
        var match$2 = match$1._0;
        if (match$2 !== undefined && match$2 !== "") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: match$1,
                      _1: "Hidden"
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: match$1,
                      _1: "Shown"
                    });
        }
    case 2 :
        return Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: Curry._3(validator.validate, input, index, metadata),
                    _1: "Hidden"
                  });
    case 3 :
        var result = Curry._3(validator.validate, input, index, metadata);
        if (result.TAG !== "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Shown"
                    });
        }
        var match$3 = result._0;
        if (match$3 !== undefined && match$3 !== "") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Hidden"
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Shown"
                    });
        }
    
  }
}

function validateFieldOnChangeInOnChangeMode(input, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  var exit$1 = 0;
  if (typeof fieldStatus !== "object" || !(fieldStatus.TAG === "Dirty" && fieldStatus._1 === "Shown")) {
    exit$1 = 3;
  } else {
    exit = 2;
  }
  if (exit$1 === 3) {
    if (submissionStatus === "NeverSubmitted") {
      switch (match) {
        case "OnFirstChange" :
            exit = 2;
            break;
        case "OnFirstSuccess" :
        case "OnFirstSuccessOrFirstBlur" :
            exit = 1;
            break;
        case "OnFirstBlur" :
        case "OnSubmit" :
            return Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: Curry._1(validator.validate, input),
                        _1: "Hidden"
                      });
        
      }
    } else {
      exit = 2;
    }
  }
  switch (exit) {
    case 1 :
        var x = Curry._1(validator.validate, input);
        if (x.TAG === "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Validating",
                      _0: x._0
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x,
                      _1: "Hidden"
                    });
        }
    case 2 :
        var x$1 = Curry._1(validator.validate, input);
        if (x$1.TAG === "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Validating",
                      _0: x$1._0
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x$1,
                      _1: "Shown"
                    });
        }
    
  }
}

function validateFieldOnChangeInOnChangeModeWithMetadata(input, metadata, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  var exit$1 = 0;
  if (typeof fieldStatus !== "object" || !(fieldStatus.TAG === "Dirty" && fieldStatus._1 === "Shown")) {
    exit$1 = 4;
  } else {
    exit = 3;
  }
  if (exit$1 === 4) {
    if (submissionStatus === "NeverSubmitted") {
      switch (match) {
        case "OnFirstChange" :
            exit = 3;
            break;
        case "OnFirstSuccess" :
        case "OnFirstSuccessOrFirstBlur" :
            exit = 1;
            break;
        case "OnFirstBlur" :
        case "OnSubmit" :
            exit = 2;
            break;
        
      }
    } else {
      exit = 3;
    }
  }
  switch (exit) {
    case 1 :
        var x = Curry._2(validator.validate, input, metadata);
        if (x.TAG === "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Validating",
                      _0: x._0
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x,
                      _1: "Hidden"
                    });
        }
    case 2 :
        return Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: Curry._2(validator.validate, input, metadata),
                    _1: "Hidden"
                  });
    case 3 :
        var x$1 = Curry._2(validator.validate, input, metadata);
        if (x$1.TAG === "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Validating",
                      _0: x$1._0
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x$1,
                      _1: "Shown"
                    });
        }
    
  }
}

function validateFieldOfCollectionOnChangeInOnChangeMode(input, index, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  var exit$1 = 0;
  if (typeof fieldStatus !== "object" || !(fieldStatus.TAG === "Dirty" && fieldStatus._1 === "Shown")) {
    exit$1 = 4;
  } else {
    exit = 3;
  }
  if (exit$1 === 4) {
    if (submissionStatus === "NeverSubmitted") {
      switch (match) {
        case "OnFirstChange" :
            exit = 3;
            break;
        case "OnFirstSuccess" :
        case "OnFirstSuccessOrFirstBlur" :
            exit = 1;
            break;
        case "OnFirstBlur" :
        case "OnSubmit" :
            exit = 2;
            break;
        
      }
    } else {
      exit = 3;
    }
  }
  switch (exit) {
    case 1 :
        var x = Curry._2(validator.validate, input, index);
        if (x.TAG === "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Validating",
                      _0: x._0
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x,
                      _1: "Hidden"
                    });
        }
    case 2 :
        return Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: Curry._2(validator.validate, input, index),
                    _1: "Hidden"
                  });
    case 3 :
        var x$1 = Curry._2(validator.validate, input, index);
        if (x$1.TAG === "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Validating",
                      _0: x$1._0
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x$1,
                      _1: "Shown"
                    });
        }
    
  }
}

function validateFieldOfCollectionOnChangeInOnChangeModeWithMetadata(input, index, metadata, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  var exit$1 = 0;
  if (typeof fieldStatus !== "object" || !(fieldStatus.TAG === "Dirty" && fieldStatus._1 === "Shown")) {
    exit$1 = 4;
  } else {
    exit = 3;
  }
  if (exit$1 === 4) {
    if (submissionStatus === "NeverSubmitted") {
      switch (match) {
        case "OnFirstChange" :
            exit = 3;
            break;
        case "OnFirstSuccess" :
        case "OnFirstSuccessOrFirstBlur" :
            exit = 1;
            break;
        case "OnFirstBlur" :
        case "OnSubmit" :
            exit = 2;
            break;
        
      }
    } else {
      exit = 3;
    }
  }
  switch (exit) {
    case 1 :
        var x = Curry._3(validator.validate, input, index, metadata);
        if (x.TAG === "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Validating",
                      _0: x._0
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x,
                      _1: "Hidden"
                    });
        }
    case 2 :
        return Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: Curry._3(validator.validate, input, index, metadata),
                    _1: "Hidden"
                  });
    case 3 :
        var x$1 = Curry._3(validator.validate, input, index, metadata);
        if (x$1.TAG === "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Validating",
                      _0: x$1._0
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x$1,
                      _1: "Shown"
                    });
        }
    
  }
}

function validateFieldOfOptionTypeOnChangeInOnChangeMode(input, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  var exit$1 = 0;
  if (typeof fieldStatus !== "object" || !(fieldStatus.TAG === "Dirty" && fieldStatus._1 === "Shown")) {
    exit$1 = 3;
  } else {
    exit = 2;
  }
  if (exit$1 === 3) {
    if (submissionStatus === "NeverSubmitted") {
      switch (match) {
        case "OnFirstChange" :
            exit = 2;
            break;
        case "OnFirstSuccess" :
        case "OnFirstSuccessOrFirstBlur" :
            exit = 1;
            break;
        case "OnFirstBlur" :
        case "OnSubmit" :
            return Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: Curry._1(validator.validate, input),
                        _1: "Hidden"
                      });
        
      }
    } else {
      exit = 2;
    }
  }
  switch (exit) {
    case 1 :
        var x = Curry._1(validator.validate, input);
        if (x.TAG !== "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x,
                      _1: "Hidden"
                    });
        }
        var x$1 = x._0;
        if (x$1 !== undefined) {
          return Curry._1(setStatus, {
                      TAG: "Validating",
                      _0: x$1
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x,
                      _1: "Shown"
                    });
        }
    case 2 :
        var x$2 = Curry._1(validator.validate, input);
        if (x$2.TAG !== "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x$2,
                      _1: "Shown"
                    });
        }
        var x$3 = x$2._0;
        if (x$3 !== undefined) {
          return Curry._1(setStatus, {
                      TAG: "Validating",
                      _0: x$3
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x$2,
                      _1: "Shown"
                    });
        }
    
  }
}

function validateFieldOfOptionTypeOnChangeInOnChangeModeWithMetadata(input, metadata, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  var exit$1 = 0;
  if (typeof fieldStatus !== "object" || !(fieldStatus.TAG === "Dirty" && fieldStatus._1 === "Shown")) {
    exit$1 = 4;
  } else {
    exit = 3;
  }
  if (exit$1 === 4) {
    if (submissionStatus === "NeverSubmitted") {
      switch (match) {
        case "OnFirstChange" :
            exit = 3;
            break;
        case "OnFirstSuccess" :
        case "OnFirstSuccessOrFirstBlur" :
            exit = 1;
            break;
        case "OnFirstBlur" :
        case "OnSubmit" :
            exit = 2;
            break;
        
      }
    } else {
      exit = 3;
    }
  }
  switch (exit) {
    case 1 :
        var x = Curry._2(validator.validate, input, metadata);
        if (x.TAG !== "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x,
                      _1: "Hidden"
                    });
        }
        var x$1 = x._0;
        if (x$1 !== undefined) {
          return Curry._1(setStatus, {
                      TAG: "Validating",
                      _0: x$1
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x,
                      _1: "Shown"
                    });
        }
    case 2 :
        return Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: Curry._2(validator.validate, input, metadata),
                    _1: "Hidden"
                  });
    case 3 :
        var x$2 = Curry._2(validator.validate, input, metadata);
        if (x$2.TAG !== "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x$2,
                      _1: "Shown"
                    });
        }
        var x$3 = x$2._0;
        if (x$3 !== undefined) {
          return Curry._1(setStatus, {
                      TAG: "Validating",
                      _0: x$3
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x$2,
                      _1: "Shown"
                    });
        }
    
  }
}

function validateFieldOfCollectionOfOptionTypeOnChangeInOnChangeMode(input, index, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  var exit$1 = 0;
  if (typeof fieldStatus !== "object" || !(fieldStatus.TAG === "Dirty" && fieldStatus._1 === "Shown")) {
    exit$1 = 4;
  } else {
    exit = 3;
  }
  if (exit$1 === 4) {
    if (submissionStatus === "NeverSubmitted") {
      switch (match) {
        case "OnFirstChange" :
            exit = 3;
            break;
        case "OnFirstSuccess" :
        case "OnFirstSuccessOrFirstBlur" :
            exit = 1;
            break;
        case "OnFirstBlur" :
        case "OnSubmit" :
            exit = 2;
            break;
        
      }
    } else {
      exit = 3;
    }
  }
  switch (exit) {
    case 1 :
        var x = Curry._2(validator.validate, input, index);
        if (x.TAG !== "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x,
                      _1: "Hidden"
                    });
        }
        var x$1 = x._0;
        if (x$1 !== undefined) {
          return Curry._1(setStatus, {
                      TAG: "Validating",
                      _0: x$1
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x,
                      _1: "Shown"
                    });
        }
    case 2 :
        return Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: Curry._2(validator.validate, input, index),
                    _1: "Hidden"
                  });
    case 3 :
        var x$2 = Curry._2(validator.validate, input, index);
        if (x$2.TAG !== "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x$2,
                      _1: "Shown"
                    });
        }
        var x$3 = x$2._0;
        if (x$3 !== undefined) {
          return Curry._1(setStatus, {
                      TAG: "Validating",
                      _0: x$3
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x$2,
                      _1: "Shown"
                    });
        }
    
  }
}

function validateFieldOfCollectionOfOptionTypeOnChangeInOnChangeModeWithMetadata(input, index, metadata, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  var exit$1 = 0;
  if (typeof fieldStatus !== "object" || !(fieldStatus.TAG === "Dirty" && fieldStatus._1 === "Shown")) {
    exit$1 = 4;
  } else {
    exit = 3;
  }
  if (exit$1 === 4) {
    if (submissionStatus === "NeverSubmitted") {
      switch (match) {
        case "OnFirstChange" :
            exit = 3;
            break;
        case "OnFirstSuccess" :
        case "OnFirstSuccessOrFirstBlur" :
            exit = 1;
            break;
        case "OnFirstBlur" :
        case "OnSubmit" :
            exit = 2;
            break;
        
      }
    } else {
      exit = 3;
    }
  }
  switch (exit) {
    case 1 :
        var x = Curry._3(validator.validate, input, index, metadata);
        if (x.TAG !== "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x,
                      _1: "Hidden"
                    });
        }
        var x$1 = x._0;
        if (x$1 !== undefined) {
          return Curry._1(setStatus, {
                      TAG: "Validating",
                      _0: x$1
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x,
                      _1: "Shown"
                    });
        }
    case 2 :
        return Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: Curry._3(validator.validate, input, index, metadata),
                    _1: "Hidden"
                  });
    case 3 :
        var x$2 = Curry._3(validator.validate, input, index, metadata);
        if (x$2.TAG !== "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x$2,
                      _1: "Shown"
                    });
        }
        var x$3 = x$2._0;
        if (x$3 !== undefined) {
          return Curry._1(setStatus, {
                      TAG: "Validating",
                      _0: x$3
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x$2,
                      _1: "Shown"
                    });
        }
    
  }
}

function validateFieldOfStringTypeOnChangeInOnChangeMode(input, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  var exit$1 = 0;
  if (typeof fieldStatus !== "object" || !(fieldStatus.TAG === "Dirty" && fieldStatus._1 === "Shown")) {
    exit$1 = 3;
  } else {
    exit = 2;
  }
  if (exit$1 === 3) {
    if (submissionStatus === "NeverSubmitted") {
      switch (match) {
        case "OnFirstChange" :
            exit = 2;
            break;
        case "OnFirstSuccess" :
        case "OnFirstSuccessOrFirstBlur" :
            exit = 1;
            break;
        case "OnFirstBlur" :
        case "OnSubmit" :
            return Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: Curry._1(validator.validate, input),
                        _1: "Hidden"
                      });
        
      }
    } else {
      exit = 2;
    }
  }
  switch (exit) {
    case 1 :
        var result = Curry._1(validator.validate, input);
        if (result.TAG !== "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Hidden"
                    });
        }
        var x = result._0;
        if (x === "") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Shown"
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Validating",
                      _0: x
                    });
        }
    case 2 :
        var x$1 = Curry._1(validator.validate, input);
        if (x$1.TAG !== "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x$1,
                      _1: "Shown"
                    });
        }
        var x$2 = x$1._0;
        if (x$2 === "") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x$1,
                      _1: "Shown"
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Validating",
                      _0: x$2
                    });
        }
    
  }
}

function validateFieldOfStringTypeOnChangeInOnChangeModeWithMetadata(input, metadata, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  var exit$1 = 0;
  if (typeof fieldStatus !== "object" || !(fieldStatus.TAG === "Dirty" && fieldStatus._1 === "Shown")) {
    exit$1 = 4;
  } else {
    exit = 3;
  }
  if (exit$1 === 4) {
    if (submissionStatus === "NeverSubmitted") {
      switch (match) {
        case "OnFirstChange" :
            exit = 3;
            break;
        case "OnFirstSuccess" :
        case "OnFirstSuccessOrFirstBlur" :
            exit = 1;
            break;
        case "OnFirstBlur" :
        case "OnSubmit" :
            exit = 2;
            break;
        
      }
    } else {
      exit = 3;
    }
  }
  switch (exit) {
    case 1 :
        var result = Curry._2(validator.validate, input, metadata);
        if (result.TAG !== "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Hidden"
                    });
        }
        var x = result._0;
        if (x === "") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Shown"
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Validating",
                      _0: x
                    });
        }
    case 2 :
        return Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: Curry._2(validator.validate, input, metadata),
                    _1: "Hidden"
                  });
    case 3 :
        var x$1 = Curry._2(validator.validate, input, metadata);
        if (x$1.TAG !== "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x$1,
                      _1: "Shown"
                    });
        }
        var x$2 = x$1._0;
        if (x$2 === "") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x$1,
                      _1: "Shown"
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Validating",
                      _0: x$2
                    });
        }
    
  }
}

function validateFieldOfCollectionOfStringTypeOnChangeInOnChangeMode(input, index, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  var exit$1 = 0;
  if (typeof fieldStatus !== "object" || !(fieldStatus.TAG === "Dirty" && fieldStatus._1 === "Shown")) {
    exit$1 = 4;
  } else {
    exit = 3;
  }
  if (exit$1 === 4) {
    if (submissionStatus === "NeverSubmitted") {
      switch (match) {
        case "OnFirstChange" :
            exit = 3;
            break;
        case "OnFirstSuccess" :
        case "OnFirstSuccessOrFirstBlur" :
            exit = 1;
            break;
        case "OnFirstBlur" :
        case "OnSubmit" :
            exit = 2;
            break;
        
      }
    } else {
      exit = 3;
    }
  }
  switch (exit) {
    case 1 :
        var result = Curry._2(validator.validate, input, index);
        if (result.TAG !== "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Hidden"
                    });
        }
        var x = result._0;
        if (x === "") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Shown"
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Validating",
                      _0: x
                    });
        }
    case 2 :
        return Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: Curry._2(validator.validate, input, index),
                    _1: "Hidden"
                  });
    case 3 :
        var x$1 = Curry._2(validator.validate, input, index);
        if (x$1.TAG !== "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x$1,
                      _1: "Shown"
                    });
        }
        var x$2 = x$1._0;
        if (x$2 === "") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x$1,
                      _1: "Shown"
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Validating",
                      _0: x$2
                    });
        }
    
  }
}

function validateFieldOfCollectionOfStringTypeOnChangeInOnChangeModeWithMetadata(input, index, metadata, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  var exit$1 = 0;
  if (typeof fieldStatus !== "object" || !(fieldStatus.TAG === "Dirty" && fieldStatus._1 === "Shown")) {
    exit$1 = 4;
  } else {
    exit = 3;
  }
  if (exit$1 === 4) {
    if (submissionStatus === "NeverSubmitted") {
      switch (match) {
        case "OnFirstChange" :
            exit = 3;
            break;
        case "OnFirstSuccess" :
        case "OnFirstSuccessOrFirstBlur" :
            exit = 1;
            break;
        case "OnFirstBlur" :
        case "OnSubmit" :
            exit = 2;
            break;
        
      }
    } else {
      exit = 3;
    }
  }
  switch (exit) {
    case 1 :
        var result = Curry._3(validator.validate, input, index, metadata);
        if (result.TAG !== "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Hidden"
                    });
        }
        var x = result._0;
        if (x === "") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: result,
                      _1: "Shown"
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Validating",
                      _0: x
                    });
        }
    case 2 :
        return Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: Curry._3(validator.validate, input, index, metadata),
                    _1: "Hidden"
                  });
    case 3 :
        var x$1 = Curry._3(validator.validate, input, index, metadata);
        if (x$1.TAG !== "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x$1,
                      _1: "Shown"
                    });
        }
        var x$2 = x$1._0;
        if (x$2 === "") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x$1,
                      _1: "Shown"
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Validating",
                      _0: x$2
                    });
        }
    
  }
}

function validateFieldOfOptionStringTypeOnChangeInOnChangeMode(input, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  var exit$1 = 0;
  if (typeof fieldStatus !== "object" || !(fieldStatus.TAG === "Dirty" && fieldStatus._1 === "Shown")) {
    exit$1 = 3;
  } else {
    exit = 2;
  }
  if (exit$1 === 3) {
    if (submissionStatus === "NeverSubmitted") {
      switch (match) {
        case "OnFirstChange" :
            exit = 2;
            break;
        case "OnFirstSuccess" :
        case "OnFirstSuccessOrFirstBlur" :
            exit = 1;
            break;
        case "OnFirstBlur" :
        case "OnSubmit" :
            return Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: Curry._1(validator.validate, input),
                        _1: "Hidden"
                      });
        
      }
    } else {
      exit = 2;
    }
  }
  switch (exit) {
    case 1 :
        var x = Curry._1(validator.validate, input);
        if (x.TAG !== "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x,
                      _1: "Hidden"
                    });
        }
        var x$1 = x._0;
        if (x$1 !== undefined && x$1 !== "") {
          return Curry._1(setStatus, {
                      TAG: "Validating",
                      _0: x$1
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x,
                      _1: "Shown"
                    });
        }
    case 2 :
        var x$2 = Curry._1(validator.validate, input);
        if (x$2.TAG !== "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x$2,
                      _1: "Shown"
                    });
        }
        var x$3 = x$2._0;
        if (x$3 !== undefined && x$3 !== "") {
          return Curry._1(setStatus, {
                      TAG: "Validating",
                      _0: x$3
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x$2,
                      _1: "Shown"
                    });
        }
    
  }
}

function validateFieldOfOptionStringTypeOnChangeInOnChangeModeWithMetadata(input, metadata, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  var exit$1 = 0;
  if (typeof fieldStatus !== "object" || !(fieldStatus.TAG === "Dirty" && fieldStatus._1 === "Shown")) {
    exit$1 = 4;
  } else {
    exit = 3;
  }
  if (exit$1 === 4) {
    if (submissionStatus === "NeverSubmitted") {
      switch (match) {
        case "OnFirstChange" :
            exit = 3;
            break;
        case "OnFirstSuccess" :
        case "OnFirstSuccessOrFirstBlur" :
            exit = 1;
            break;
        case "OnFirstBlur" :
        case "OnSubmit" :
            exit = 2;
            break;
        
      }
    } else {
      exit = 3;
    }
  }
  switch (exit) {
    case 1 :
        var x = Curry._2(validator.validate, input, metadata);
        if (x.TAG !== "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x,
                      _1: "Hidden"
                    });
        }
        var x$1 = x._0;
        if (x$1 !== undefined && x$1 !== "") {
          return Curry._1(setStatus, {
                      TAG: "Validating",
                      _0: x$1
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x,
                      _1: "Shown"
                    });
        }
    case 2 :
        return Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: Curry._2(validator.validate, input, metadata),
                    _1: "Hidden"
                  });
    case 3 :
        var x$2 = Curry._2(validator.validate, input, metadata);
        if (x$2.TAG !== "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x$2,
                      _1: "Shown"
                    });
        }
        var x$3 = x$2._0;
        if (x$3 !== undefined && x$3 !== "") {
          return Curry._1(setStatus, {
                      TAG: "Validating",
                      _0: x$3
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x$2,
                      _1: "Shown"
                    });
        }
    
  }
}

function validateFieldOfCollectionOfOptionStringTypeOnChangeInOnChangeMode(input, index, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  var exit$1 = 0;
  if (typeof fieldStatus !== "object" || !(fieldStatus.TAG === "Dirty" && fieldStatus._1 === "Shown")) {
    exit$1 = 4;
  } else {
    exit = 3;
  }
  if (exit$1 === 4) {
    if (submissionStatus === "NeverSubmitted") {
      switch (match) {
        case "OnFirstChange" :
            exit = 3;
            break;
        case "OnFirstSuccess" :
        case "OnFirstSuccessOrFirstBlur" :
            exit = 1;
            break;
        case "OnFirstBlur" :
        case "OnSubmit" :
            exit = 2;
            break;
        
      }
    } else {
      exit = 3;
    }
  }
  switch (exit) {
    case 1 :
        var x = Curry._2(validator.validate, input, index);
        if (x.TAG !== "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x,
                      _1: "Hidden"
                    });
        }
        var x$1 = x._0;
        if (x$1 !== undefined && x$1 !== "") {
          return Curry._1(setStatus, {
                      TAG: "Validating",
                      _0: x$1
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x,
                      _1: "Shown"
                    });
        }
    case 2 :
        return Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: Curry._2(validator.validate, input, index),
                    _1: "Hidden"
                  });
    case 3 :
        var x$2 = Curry._2(validator.validate, input, index);
        if (x$2.TAG !== "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x$2,
                      _1: "Shown"
                    });
        }
        var x$3 = x$2._0;
        if (x$3 !== undefined && x$3 !== "") {
          return Curry._1(setStatus, {
                      TAG: "Validating",
                      _0: x$3
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x$2,
                      _1: "Shown"
                    });
        }
    
  }
}

function validateFieldOfCollectionOfOptionStringTypeOnChangeInOnChangeModeWithMetadata(input, index, metadata, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  var exit$1 = 0;
  if (typeof fieldStatus !== "object" || !(fieldStatus.TAG === "Dirty" && fieldStatus._1 === "Shown")) {
    exit$1 = 4;
  } else {
    exit = 3;
  }
  if (exit$1 === 4) {
    if (submissionStatus === "NeverSubmitted") {
      switch (match) {
        case "OnFirstChange" :
            exit = 3;
            break;
        case "OnFirstSuccess" :
        case "OnFirstSuccessOrFirstBlur" :
            exit = 1;
            break;
        case "OnFirstBlur" :
        case "OnSubmit" :
            exit = 2;
            break;
        
      }
    } else {
      exit = 3;
    }
  }
  switch (exit) {
    case 1 :
        var x = Curry._3(validator.validate, input, index, metadata);
        if (x.TAG !== "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x,
                      _1: "Hidden"
                    });
        }
        var x$1 = x._0;
        if (x$1 !== undefined && x$1 !== "") {
          return Curry._1(setStatus, {
                      TAG: "Validating",
                      _0: x$1
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x,
                      _1: "Shown"
                    });
        }
    case 2 :
        return Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: Curry._3(validator.validate, input, index, metadata),
                    _1: "Hidden"
                  });
    case 3 :
        var x$2 = Curry._3(validator.validate, input, index, metadata);
        if (x$2.TAG !== "Ok") {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x$2,
                      _1: "Shown"
                    });
        }
        var x$3 = x$2._0;
        if (x$3 !== undefined && x$3 !== "") {
          return Curry._1(setStatus, {
                      TAG: "Validating",
                      _0: x$3
                    });
        } else {
          return Curry._1(setStatus, {
                      TAG: "Dirty",
                      _0: x$2,
                      _1: "Shown"
                    });
        }
    
  }
}

function validateDependentFieldOnChange$1(input, fieldStatus, validator, setStatus) {
  if (typeof fieldStatus !== "object" || !(fieldStatus.TAG === "Dirty" && fieldStatus._1 === "Shown")) {
    return ;
  } else {
    return Caml_option.some(Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: Curry._1(validator.validate, input),
                    _1: "Shown"
                  }));
  }
}

function validateDependentFieldOnChangeWithMetadata$1(input, metadata, fieldStatus, validator, setStatus) {
  if (typeof fieldStatus !== "object" || !(fieldStatus.TAG === "Dirty" && fieldStatus._1 === "Shown")) {
    return ;
  } else {
    return Caml_option.some(Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: Curry._2(validator.validate, input, metadata),
                    _1: "Shown"
                  }));
  }
}

function validateDependentFieldOfCollectionOnChange$1(input, index, fieldStatus, validator, setStatus) {
  if (typeof fieldStatus !== "object" || !(fieldStatus.TAG === "Dirty" && fieldStatus._1 === "Shown")) {
    return ;
  } else {
    return Caml_option.some(Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: Curry._2(validator.validate, input, index),
                    _1: "Shown"
                  }));
  }
}

function validateDependentFieldOfCollectionOnChangeWithMetadata$1(input, index, metadata, fieldStatus, validator, setStatus) {
  if (typeof fieldStatus !== "object" || !(fieldStatus.TAG === "Dirty" && fieldStatus._1 === "Shown")) {
    return ;
  } else {
    return Caml_option.some(Curry._1(setStatus, {
                    TAG: "Dirty",
                    _0: Curry._3(validator.validate, input, index, metadata),
                    _1: "Shown"
                  }));
  }
}

function validateFieldOnBlur(input, fieldStatus, validator, setStatus) {
  var exit = 0;
  if (typeof fieldStatus !== "object") {
    exit = 1;
  } else {
    if (fieldStatus.TAG !== "Dirty") {
      return ;
    }
    if (fieldStatus._1 === "Shown") {
      return ;
    }
    exit = 1;
  }
  if (exit === 1) {
    var match = validator.strategy;
    var exit$1 = 0;
    switch (match) {
      case "OnFirstBlur" :
      case "OnFirstSuccessOrFirstBlur" :
          exit$1 = 2;
          break;
      default:
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: Curry._1(validator.validate, input),
                        _1: "Hidden"
                      }));
    }
    if (exit$1 === 2) {
      var x = Curry._1(validator.validate, input);
      if (x.TAG === "Ok") {
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Validating",
                        _0: x._0
                      }));
      } else {
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: x,
                        _1: "Shown"
                      }));
      }
    }
    
  }
  
}

function validateFieldOnBlurWithMetadata(input, metadata, fieldStatus, validator, setStatus) {
  var exit = 0;
  if (typeof fieldStatus !== "object") {
    exit = 1;
  } else {
    if (fieldStatus.TAG !== "Dirty") {
      return ;
    }
    if (fieldStatus._1 === "Shown") {
      return ;
    }
    exit = 1;
  }
  if (exit === 1) {
    var match = validator.strategy;
    var exit$1 = 0;
    switch (match) {
      case "OnFirstBlur" :
      case "OnFirstSuccessOrFirstBlur" :
          exit$1 = 2;
          break;
      default:
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: Curry._2(validator.validate, input, metadata),
                        _1: "Hidden"
                      }));
    }
    if (exit$1 === 2) {
      var x = Curry._2(validator.validate, input, metadata);
      if (x.TAG === "Ok") {
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Validating",
                        _0: x._0
                      }));
      } else {
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: x,
                        _1: "Shown"
                      }));
      }
    }
    
  }
  
}

function validateFieldOfCollectionOnBlur(input, index, fieldStatus, validator, setStatus) {
  var exit = 0;
  if (typeof fieldStatus !== "object") {
    exit = 1;
  } else {
    if (fieldStatus.TAG !== "Dirty") {
      return ;
    }
    if (fieldStatus._1 === "Shown") {
      return ;
    }
    exit = 1;
  }
  if (exit === 1) {
    var match = validator.strategy;
    var exit$1 = 0;
    switch (match) {
      case "OnFirstBlur" :
      case "OnFirstSuccessOrFirstBlur" :
          exit$1 = 2;
          break;
      default:
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: Curry._2(validator.validate, input, index),
                        _1: "Hidden"
                      }));
    }
    if (exit$1 === 2) {
      var x = Curry._2(validator.validate, input, index);
      if (x.TAG === "Ok") {
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Validating",
                        _0: x._0
                      }));
      } else {
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: x,
                        _1: "Shown"
                      }));
      }
    }
    
  }
  
}

function validateFieldOfCollectionOnBlurWithMetadata(input, index, metadata, fieldStatus, validator, setStatus) {
  var exit = 0;
  if (typeof fieldStatus !== "object") {
    exit = 1;
  } else {
    if (fieldStatus.TAG !== "Dirty") {
      return ;
    }
    if (fieldStatus._1 === "Shown") {
      return ;
    }
    exit = 1;
  }
  if (exit === 1) {
    var match = validator.strategy;
    var exit$1 = 0;
    switch (match) {
      case "OnFirstBlur" :
      case "OnFirstSuccessOrFirstBlur" :
          exit$1 = 2;
          break;
      default:
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: Curry._3(validator.validate, input, index, metadata),
                        _1: "Hidden"
                      }));
    }
    if (exit$1 === 2) {
      var x = Curry._3(validator.validate, input, index, metadata);
      if (x.TAG === "Ok") {
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Validating",
                        _0: x._0
                      }));
      } else {
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: x,
                        _1: "Shown"
                      }));
      }
    }
    
  }
  
}

function validateFieldOfOptionTypeOnBlur(input, fieldStatus, validator, setStatus) {
  var exit = 0;
  if (typeof fieldStatus !== "object") {
    exit = 1;
  } else {
    if (fieldStatus.TAG !== "Dirty") {
      return ;
    }
    if (fieldStatus._1 === "Shown") {
      return ;
    }
    exit = 1;
  }
  if (exit === 1) {
    var match = validator.strategy;
    var exit$1 = 0;
    switch (match) {
      case "OnFirstBlur" :
      case "OnFirstSuccessOrFirstBlur" :
          exit$1 = 2;
          break;
      default:
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: Curry._1(validator.validate, input),
                        _1: "Hidden"
                      }));
    }
    if (exit$1 === 2) {
      var x = Curry._1(validator.validate, input);
      if (x.TAG !== "Ok") {
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: x,
                        _1: "Shown"
                      }));
      }
      var x$1 = x._0;
      if (x$1 !== undefined) {
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Validating",
                        _0: x$1
                      }));
      } else {
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: x,
                        _1: "Shown"
                      }));
      }
    }
    
  }
  
}

function validateFieldOfOptionTypeOnBlurWithMetadata(input, metadata, fieldStatus, validator, setStatus) {
  var exit = 0;
  if (typeof fieldStatus !== "object") {
    exit = 1;
  } else {
    if (fieldStatus.TAG !== "Dirty") {
      return ;
    }
    if (fieldStatus._1 === "Shown") {
      return ;
    }
    exit = 1;
  }
  if (exit === 1) {
    var match = validator.strategy;
    var exit$1 = 0;
    switch (match) {
      case "OnFirstBlur" :
      case "OnFirstSuccessOrFirstBlur" :
          exit$1 = 2;
          break;
      default:
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: Curry._2(validator.validate, input, metadata),
                        _1: "Hidden"
                      }));
    }
    if (exit$1 === 2) {
      var x = Curry._2(validator.validate, input, metadata);
      if (x.TAG !== "Ok") {
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: x,
                        _1: "Shown"
                      }));
      }
      var x$1 = x._0;
      if (x$1 !== undefined) {
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Validating",
                        _0: x$1
                      }));
      } else {
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: x,
                        _1: "Shown"
                      }));
      }
    }
    
  }
  
}

function validateFieldOfCollectionOfOptionTypeOnBlur(input, index, fieldStatus, validator, setStatus) {
  var exit = 0;
  if (typeof fieldStatus !== "object") {
    exit = 1;
  } else {
    if (fieldStatus.TAG !== "Dirty") {
      return ;
    }
    if (fieldStatus._1 === "Shown") {
      return ;
    }
    exit = 1;
  }
  if (exit === 1) {
    var match = validator.strategy;
    var exit$1 = 0;
    switch (match) {
      case "OnFirstBlur" :
      case "OnFirstSuccessOrFirstBlur" :
          exit$1 = 2;
          break;
      default:
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: Curry._2(validator.validate, input, index),
                        _1: "Hidden"
                      }));
    }
    if (exit$1 === 2) {
      var x = Curry._2(validator.validate, input, index);
      if (x.TAG !== "Ok") {
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: x,
                        _1: "Shown"
                      }));
      }
      var x$1 = x._0;
      if (x$1 !== undefined) {
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Validating",
                        _0: x$1
                      }));
      } else {
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: x,
                        _1: "Shown"
                      }));
      }
    }
    
  }
  
}

function validateFieldOfCollectionOfOptionTypeOnBlurWithMetadata(input, index, metadata, fieldStatus, validator, setStatus) {
  var exit = 0;
  if (typeof fieldStatus !== "object") {
    exit = 1;
  } else {
    if (fieldStatus.TAG !== "Dirty") {
      return ;
    }
    if (fieldStatus._1 === "Shown") {
      return ;
    }
    exit = 1;
  }
  if (exit === 1) {
    var match = validator.strategy;
    var exit$1 = 0;
    switch (match) {
      case "OnFirstBlur" :
      case "OnFirstSuccessOrFirstBlur" :
          exit$1 = 2;
          break;
      default:
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: Curry._3(validator.validate, input, index, metadata),
                        _1: "Hidden"
                      }));
    }
    if (exit$1 === 2) {
      var x = Curry._3(validator.validate, input, index, metadata);
      if (x.TAG !== "Ok") {
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: x,
                        _1: "Shown"
                      }));
      }
      var x$1 = x._0;
      if (x$1 !== undefined) {
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Validating",
                        _0: x$1
                      }));
      } else {
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: x,
                        _1: "Shown"
                      }));
      }
    }
    
  }
  
}

function validateFieldOfStringTypeOnBlur(input, fieldStatus, validator, setStatus) {
  var exit = 0;
  if (typeof fieldStatus !== "object") {
    exit = 1;
  } else {
    if (fieldStatus.TAG !== "Dirty") {
      return ;
    }
    if (fieldStatus._1 === "Shown") {
      return ;
    }
    exit = 1;
  }
  if (exit === 1) {
    var match = validator.strategy;
    var exit$1 = 0;
    switch (match) {
      case "OnFirstBlur" :
      case "OnFirstSuccessOrFirstBlur" :
          exit$1 = 2;
          break;
      default:
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: Curry._1(validator.validate, input),
                        _1: "Hidden"
                      }));
    }
    if (exit$1 === 2) {
      var x = Curry._1(validator.validate, input);
      if (x.TAG !== "Ok") {
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: x,
                        _1: "Shown"
                      }));
      }
      var x$1 = x._0;
      if (x$1 === "") {
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: x,
                        _1: "Shown"
                      }));
      } else {
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Validating",
                        _0: x$1
                      }));
      }
    }
    
  }
  
}

function validateFieldOfStringTypeOnBlurWithMetadata(input, metadata, fieldStatus, validator, setStatus) {
  var exit = 0;
  if (typeof fieldStatus !== "object") {
    exit = 1;
  } else {
    if (fieldStatus.TAG !== "Dirty") {
      return ;
    }
    if (fieldStatus._1 === "Shown") {
      return ;
    }
    exit = 1;
  }
  if (exit === 1) {
    var match = validator.strategy;
    var exit$1 = 0;
    switch (match) {
      case "OnFirstBlur" :
      case "OnFirstSuccessOrFirstBlur" :
          exit$1 = 2;
          break;
      default:
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: Curry._2(validator.validate, input, metadata),
                        _1: "Hidden"
                      }));
    }
    if (exit$1 === 2) {
      var x = Curry._2(validator.validate, input, metadata);
      if (x.TAG !== "Ok") {
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: x,
                        _1: "Shown"
                      }));
      }
      var x$1 = x._0;
      if (x$1 === "") {
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: x,
                        _1: "Shown"
                      }));
      } else {
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Validating",
                        _0: x$1
                      }));
      }
    }
    
  }
  
}

function validateFieldOfCollectionOfStringTypeOnBlur(input, index, fieldStatus, validator, setStatus) {
  var exit = 0;
  if (typeof fieldStatus !== "object") {
    exit = 1;
  } else {
    if (fieldStatus.TAG !== "Dirty") {
      return ;
    }
    if (fieldStatus._1 === "Shown") {
      return ;
    }
    exit = 1;
  }
  if (exit === 1) {
    var match = validator.strategy;
    var exit$1 = 0;
    switch (match) {
      case "OnFirstBlur" :
      case "OnFirstSuccessOrFirstBlur" :
          exit$1 = 2;
          break;
      default:
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: Curry._2(validator.validate, input, index),
                        _1: "Hidden"
                      }));
    }
    if (exit$1 === 2) {
      var x = Curry._2(validator.validate, input, index);
      if (x.TAG !== "Ok") {
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: x,
                        _1: "Shown"
                      }));
      }
      var x$1 = x._0;
      if (x$1 === "") {
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: x,
                        _1: "Shown"
                      }));
      } else {
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Validating",
                        _0: x$1
                      }));
      }
    }
    
  }
  
}

function validateFieldOfCollectionOfStringTypeOnBlurWithMetadata(input, index, metadata, fieldStatus, validator, setStatus) {
  var exit = 0;
  if (typeof fieldStatus !== "object") {
    exit = 1;
  } else {
    if (fieldStatus.TAG !== "Dirty") {
      return ;
    }
    if (fieldStatus._1 === "Shown") {
      return ;
    }
    exit = 1;
  }
  if (exit === 1) {
    var match = validator.strategy;
    var exit$1 = 0;
    switch (match) {
      case "OnFirstBlur" :
      case "OnFirstSuccessOrFirstBlur" :
          exit$1 = 2;
          break;
      default:
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: Curry._3(validator.validate, input, index, metadata),
                        _1: "Hidden"
                      }));
    }
    if (exit$1 === 2) {
      var x = Curry._3(validator.validate, input, index, metadata);
      if (x.TAG !== "Ok") {
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: x,
                        _1: "Shown"
                      }));
      }
      var x$1 = x._0;
      if (x$1 === "") {
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: x,
                        _1: "Shown"
                      }));
      } else {
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Validating",
                        _0: x$1
                      }));
      }
    }
    
  }
  
}

function validateFieldOfOptionStringTypeOnBlur(input, fieldStatus, validator, setStatus) {
  var exit = 0;
  if (typeof fieldStatus !== "object") {
    exit = 1;
  } else {
    if (fieldStatus.TAG !== "Dirty") {
      return ;
    }
    if (fieldStatus._1 === "Shown") {
      return ;
    }
    exit = 1;
  }
  if (exit === 1) {
    var match = validator.strategy;
    var exit$1 = 0;
    switch (match) {
      case "OnFirstBlur" :
      case "OnFirstSuccessOrFirstBlur" :
          exit$1 = 2;
          break;
      default:
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: Curry._1(validator.validate, input),
                        _1: "Hidden"
                      }));
    }
    if (exit$1 === 2) {
      var x = Curry._1(validator.validate, input);
      var exit$2 = 0;
      var result;
      if (x.TAG === "Ok") {
        var x$1 = x._0;
        if (x$1 !== undefined) {
          if (x$1 !== "") {
            return Caml_option.some(Curry._1(setStatus, {
                            TAG: "Validating",
                            _0: x$1
                          }));
          }
          result = x;
          exit$2 = 3;
        } else {
          result = x;
          exit$2 = 3;
        }
      } else {
        result = x;
        exit$2 = 3;
      }
      if (exit$2 === 3) {
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: result,
                        _1: "Shown"
                      }));
      }
      
    }
    
  }
  
}

function validateFieldOfOptionStringTypeOnBlurWithMetadata(input, metadata, fieldStatus, validator, setStatus) {
  var exit = 0;
  if (typeof fieldStatus !== "object") {
    exit = 1;
  } else {
    if (fieldStatus.TAG !== "Dirty") {
      return ;
    }
    if (fieldStatus._1 === "Shown") {
      return ;
    }
    exit = 1;
  }
  if (exit === 1) {
    var match = validator.strategy;
    var exit$1 = 0;
    switch (match) {
      case "OnFirstBlur" :
      case "OnFirstSuccessOrFirstBlur" :
          exit$1 = 2;
          break;
      default:
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: Curry._2(validator.validate, input, metadata),
                        _1: "Hidden"
                      }));
    }
    if (exit$1 === 2) {
      var x = Curry._2(validator.validate, input, metadata);
      var exit$2 = 0;
      var result;
      if (x.TAG === "Ok") {
        var x$1 = x._0;
        if (x$1 !== undefined) {
          if (x$1 !== "") {
            return Caml_option.some(Curry._1(setStatus, {
                            TAG: "Validating",
                            _0: x$1
                          }));
          }
          result = x;
          exit$2 = 3;
        } else {
          result = x;
          exit$2 = 3;
        }
      } else {
        result = x;
        exit$2 = 3;
      }
      if (exit$2 === 3) {
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: result,
                        _1: "Shown"
                      }));
      }
      
    }
    
  }
  
}

function validateFieldOfCollectionOfOptionStringTypeOnBlur(input, index, fieldStatus, validator, setStatus) {
  var exit = 0;
  if (typeof fieldStatus !== "object") {
    exit = 1;
  } else {
    if (fieldStatus.TAG !== "Dirty") {
      return ;
    }
    if (fieldStatus._1 === "Shown") {
      return ;
    }
    exit = 1;
  }
  if (exit === 1) {
    var match = validator.strategy;
    var exit$1 = 0;
    switch (match) {
      case "OnFirstBlur" :
      case "OnFirstSuccessOrFirstBlur" :
          exit$1 = 2;
          break;
      default:
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: Curry._2(validator.validate, input, index),
                        _1: "Hidden"
                      }));
    }
    if (exit$1 === 2) {
      var x = Curry._2(validator.validate, input, index);
      var exit$2 = 0;
      var result;
      if (x.TAG === "Ok") {
        var x$1 = x._0;
        if (x$1 !== undefined) {
          if (x$1 !== "") {
            return Caml_option.some(Curry._1(setStatus, {
                            TAG: "Validating",
                            _0: x$1
                          }));
          }
          result = x;
          exit$2 = 3;
        } else {
          result = x;
          exit$2 = 3;
        }
      } else {
        result = x;
        exit$2 = 3;
      }
      if (exit$2 === 3) {
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: result,
                        _1: "Shown"
                      }));
      }
      
    }
    
  }
  
}

function validateFieldOfCollectionOfOptionStringTypeOnBlurWithMetadata(input, index, metadata, fieldStatus, validator, setStatus) {
  var exit = 0;
  if (typeof fieldStatus !== "object") {
    exit = 1;
  } else {
    if (fieldStatus.TAG !== "Dirty") {
      return ;
    }
    if (fieldStatus._1 === "Shown") {
      return ;
    }
    exit = 1;
  }
  if (exit === 1) {
    var match = validator.strategy;
    var exit$1 = 0;
    switch (match) {
      case "OnFirstBlur" :
      case "OnFirstSuccessOrFirstBlur" :
          exit$1 = 2;
          break;
      default:
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: Curry._3(validator.validate, input, index, metadata),
                        _1: "Hidden"
                      }));
    }
    if (exit$1 === 2) {
      var x = Curry._3(validator.validate, input, index, metadata);
      var exit$2 = 0;
      var result;
      if (x.TAG === "Ok") {
        var x$1 = x._0;
        if (x$1 !== undefined) {
          if (x$1 !== "") {
            return Caml_option.some(Curry._1(setStatus, {
                            TAG: "Validating",
                            _0: x$1
                          }));
          }
          result = x;
          exit$2 = 3;
        } else {
          result = x;
          exit$2 = 3;
        }
      } else {
        result = x;
        exit$2 = 3;
      }
      if (exit$2 === 3) {
        return Caml_option.some(Curry._1(setStatus, {
                        TAG: "Dirty",
                        _0: result,
                        _1: "Shown"
                      }));
      }
      
    }
    
  }
  
}

var Async = {
  validateAsync: validateAsync,
  validateAsyncWithMetadata: validateAsyncWithMetadata,
  exposeFieldResult: exposeFieldResult$1,
  validateFieldOnChangeInOnBlurMode: validateFieldOnChangeInOnBlurMode,
  validateFieldOnChangeInOnBlurModeWithMetadata: validateFieldOnChangeInOnBlurModeWithMetadata,
  validateFieldOfCollectionOnChangeInOnBlurMode: validateFieldOfCollectionOnChangeInOnBlurMode,
  validateFieldOfCollectionOnChangeInOnBlurModeWithMetadata: validateFieldOfCollectionOnChangeInOnBlurModeWithMetadata,
  validateFieldOfOptionTypeOnChangeInOnBlurMode: validateFieldOfOptionTypeOnChangeInOnBlurMode,
  validateFieldOfOptionTypeOnChangeInOnBlurModeWithMetadata: validateFieldOfOptionTypeOnChangeInOnBlurModeWithMetadata,
  validateFieldOfCollectionOfOptionTypeOnChangeInOnBlurMode: validateFieldOfCollectionOfOptionTypeOnChangeInOnBlurMode,
  validateFieldOfCollectionOfOptionTypeOnChangeInOnBlurModeWithMetadata: validateFieldOfCollectionOfOptionTypeOnChangeInOnBlurModeWithMetadata,
  validateFieldOfStringTypeOnChangeInOnBlurMode: validateFieldOfStringTypeOnChangeInOnBlurMode,
  validateFieldOfStringTypeOnChangeInOnBlurModeWithMetadata: validateFieldOfStringTypeOnChangeInOnBlurModeWithMetadata,
  validateFieldOfCollectionOfStringTypeOnChangeInOnBlurMode: validateFieldOfCollectionOfStringTypeOnChangeInOnBlurMode,
  validateFieldOfCollectionOfStringTypeOnChangeInOnBlurModeWithMetadata: validateFieldOfCollectionOfStringTypeOnChangeInOnBlurModeWithMetadata,
  validateFieldOfOptionStringTypeOnChangeInOnBlurMode: validateFieldOfOptionStringTypeOnChangeInOnBlurMode,
  validateFieldOfOptionStringTypeOnChangeInOnBlurModeWithMetadata: validateFieldOfOptionStringTypeOnChangeInOnBlurModeWithMetadata,
  validateFieldOfCollectionOfOptionStringTypeOnChangeInOnBlurMode: validateFieldOfCollectionOfOptionStringTypeOnChangeInOnBlurMode,
  validateFieldOfCollectionOfOptionStringTypeOnChangeInOnBlurModeWithMetadata: validateFieldOfCollectionOfOptionStringTypeOnChangeInOnBlurModeWithMetadata,
  validateFieldOnChangeInOnChangeMode: validateFieldOnChangeInOnChangeMode,
  validateFieldOnChangeInOnChangeModeWithMetadata: validateFieldOnChangeInOnChangeModeWithMetadata,
  validateFieldOfCollectionOnChangeInOnChangeMode: validateFieldOfCollectionOnChangeInOnChangeMode,
  validateFieldOfCollectionOnChangeInOnChangeModeWithMetadata: validateFieldOfCollectionOnChangeInOnChangeModeWithMetadata,
  validateFieldOfOptionTypeOnChangeInOnChangeMode: validateFieldOfOptionTypeOnChangeInOnChangeMode,
  validateFieldOfOptionTypeOnChangeInOnChangeModeWithMetadata: validateFieldOfOptionTypeOnChangeInOnChangeModeWithMetadata,
  validateFieldOfCollectionOfOptionTypeOnChangeInOnChangeMode: validateFieldOfCollectionOfOptionTypeOnChangeInOnChangeMode,
  validateFieldOfCollectionOfOptionTypeOnChangeInOnChangeModeWithMetadata: validateFieldOfCollectionOfOptionTypeOnChangeInOnChangeModeWithMetadata,
  validateFieldOfStringTypeOnChangeInOnChangeMode: validateFieldOfStringTypeOnChangeInOnChangeMode,
  validateFieldOfStringTypeOnChangeInOnChangeModeWithMetadata: validateFieldOfStringTypeOnChangeInOnChangeModeWithMetadata,
  validateFieldOfCollectionOfStringTypeOnChangeInOnChangeMode: validateFieldOfCollectionOfStringTypeOnChangeInOnChangeMode,
  validateFieldOfCollectionOfStringTypeOnChangeInOnChangeModeWithMetadata: validateFieldOfCollectionOfStringTypeOnChangeInOnChangeModeWithMetadata,
  validateFieldOfOptionStringTypeOnChangeInOnChangeMode: validateFieldOfOptionStringTypeOnChangeInOnChangeMode,
  validateFieldOfOptionStringTypeOnChangeInOnChangeModeWithMetadata: validateFieldOfOptionStringTypeOnChangeInOnChangeModeWithMetadata,
  validateFieldOfCollectionOfOptionStringTypeOnChangeInOnChangeMode: validateFieldOfCollectionOfOptionStringTypeOnChangeInOnChangeMode,
  validateFieldOfCollectionOfOptionStringTypeOnChangeInOnChangeModeWithMetadata: validateFieldOfCollectionOfOptionStringTypeOnChangeInOnChangeModeWithMetadata,
  validateDependentFieldOnChange: validateDependentFieldOnChange$1,
  validateDependentFieldOnChangeWithMetadata: validateDependentFieldOnChangeWithMetadata$1,
  validateDependentFieldOfCollectionOnChange: validateDependentFieldOfCollectionOnChange$1,
  validateDependentFieldOfCollectionOnChangeWithMetadata: validateDependentFieldOfCollectionOnChangeWithMetadata$1,
  validateFieldOnBlur: validateFieldOnBlur,
  validateFieldOnBlurWithMetadata: validateFieldOnBlurWithMetadata,
  validateFieldOfCollectionOnBlur: validateFieldOfCollectionOnBlur,
  validateFieldOfCollectionOnBlurWithMetadata: validateFieldOfCollectionOnBlurWithMetadata,
  validateFieldOfOptionTypeOnBlur: validateFieldOfOptionTypeOnBlur,
  validateFieldOfOptionTypeOnBlurWithMetadata: validateFieldOfOptionTypeOnBlurWithMetadata,
  validateFieldOfCollectionOfOptionTypeOnBlur: validateFieldOfCollectionOfOptionTypeOnBlur,
  validateFieldOfCollectionOfOptionTypeOnBlurWithMetadata: validateFieldOfCollectionOfOptionTypeOnBlurWithMetadata,
  validateFieldOfStringTypeOnBlur: validateFieldOfStringTypeOnBlur,
  validateFieldOfStringTypeOnBlurWithMetadata: validateFieldOfStringTypeOnBlurWithMetadata,
  validateFieldOfCollectionOfStringTypeOnBlur: validateFieldOfCollectionOfStringTypeOnBlur,
  validateFieldOfCollectionOfStringTypeOnBlurWithMetadata: validateFieldOfCollectionOfStringTypeOnBlurWithMetadata,
  validateFieldOfOptionStringTypeOnBlur: validateFieldOfOptionStringTypeOnBlur,
  validateFieldOfOptionStringTypeOnBlurWithMetadata: validateFieldOfOptionStringTypeOnBlurWithMetadata,
  validateFieldOfCollectionOfOptionStringTypeOnBlur: validateFieldOfCollectionOfOptionStringTypeOnBlur,
  validateFieldOfCollectionOfOptionStringTypeOnBlurWithMetadata: validateFieldOfCollectionOfOptionStringTypeOnBlurWithMetadata
};

var Debouncer;

var ReactUpdate;

exports.Debouncer = Debouncer;
exports.ReactUpdate = ReactUpdate;
exports.exposeFieldResult = exposeFieldResult;
exports.validateFieldOnChangeWithoutValidator = validateFieldOnChangeWithoutValidator;
exports.validateFieldOnChangeWithValidator = validateFieldOnChangeWithValidator;
exports.validateFieldOnChangeWithValidatorAndMetadata = validateFieldOnChangeWithValidatorAndMetadata;
exports.validateFieldOfCollectionOnChangeWithValidator = validateFieldOfCollectionOnChangeWithValidator;
exports.validateFieldOfCollectionOnChangeWithValidatorAndMetadata = validateFieldOfCollectionOnChangeWithValidatorAndMetadata;
exports.validateDependentFieldOnChange = validateDependentFieldOnChange;
exports.validateDependentFieldOnChangeWithMetadata = validateDependentFieldOnChangeWithMetadata;
exports.validateDependentFieldOfCollectionOnChange = validateDependentFieldOfCollectionOnChange;
exports.validateDependentFieldOfCollectionOnChangeWithMetadata = validateDependentFieldOfCollectionOnChangeWithMetadata;
exports.validateFieldOnBlurWithoutValidator = validateFieldOnBlurWithoutValidator;
exports.validateFieldOnBlurWithValidator = validateFieldOnBlurWithValidator;
exports.validateFieldOnBlurWithValidatorAndMetadata = validateFieldOnBlurWithValidatorAndMetadata;
exports.validateFieldOfCollectionOnBlurWithValidator = validateFieldOfCollectionOnBlurWithValidator;
exports.validateFieldOfCollectionOnBlurWithValidatorAndMetadata = validateFieldOfCollectionOnBlurWithValidatorAndMetadata;
exports.Async = Async;
/* No side effect */
